import { environment } from '@config/environment'
import { cleanContentfulEntry } from '@utils/contentful'
import { PgPageProps } from 'pages/[[...slug]]'
import {
  ContentfulObjFlooringWeave,
  ContentfulObjFurnitureProduct,
  ContentfulOrFooter,
  ContentfulOrHeader,
  ContentfulPgPage,
  ContentfulProductQuantityInfo,
  ContentfulSynsisalWeaveColorGroup,
  ContentfulWeaveColorBorderGroup,
  ContentType,
  SiteDomain,
  Status,
} from 'types'

import { getClient, getProductsClient } from './client'

const getEntries = <T = unknown>(query: Record<string, unknown>, isPreview?: boolean) => {
  return getClient(isPreview).getEntries<T>(query)
}

const getEntryById = <T = unknown>(id: string, isPreview?: boolean, query?: Record<string, unknown>) => {
  return getClient(isPreview).getEntry<T>(id, query)
}

const getPageEntries = <T = unknown>(
  content_type: ContentType,
  isPreview?: boolean,
  query?: Record<string, unknown>,
) => {
  return getEntries<T>(
    {
      content_type,
      ...query,
    },
    isPreview,
  )
}

export const getProductEntries = <T = unknown>(query: Record<string, unknown>, isPreview?: boolean) => {
  return getProductsClient(isPreview).getEntries<T>(query)
}

const getPageProductEntries = <T = unknown>(
  content_type: ContentType,
  isPreview?: boolean,
  query?: Record<string, unknown>,
) => {
  return getProductEntries<T>(
    {
      content_type,
      ...query,
    },
    isPreview,
  )
}

export const getProductById = async (id: string, isPreview?: boolean) => {
  const product = await getEntryById<ContentfulObjFurnitureProduct>(id, isPreview, {
    include: 10,
  })

  return product ? (cleanContentfulEntry(product) as ContentfulObjFurnitureProduct) : null
}

export const getAllSlugs = async () => {
  const { items } = await getPageEntries<{ slug: string }>(ContentType.PG_PAGE, false, {
    select: ['fields.slug'],
    'fields.sites': environment.siteDomain || SiteDomain.FURNITURE,
  })

  return items.map((item) => {
    return item.fields.slug.split('/').filter((path) => Boolean(path))
  })
}

export const getAllProductSlugs = async () => {
  if (environment.siteDomain === SiteDomain.FURNITURE) {
    const { items } = await getPageProductEntries<{ productNameSlug: string }>(
      ContentType.OBJ_FURNITURE_PRODUCT,
      false,
      {
        select: ['fields.productNameSlug'],
        'fields.status': Status.ACTIVE,
      },
    )

    return items.map((item) => item.fields.productNameSlug.split('/').filter((path) => Boolean(path)))
  } else if (environment.siteDomain === SiteDomain.FLOORING || environment.siteDomain === SiteDomain.SISALCARPET) {
    const { items } = await getPageProductEntries<{ weaveNameSlug: string }>(ContentType.OBJ_FLOORING_WEAVE, false, {
      select: ['fields.weaveNameSlug'],
      'fields.status': Status.ACTIVE,
    })

    return items.map((item) => item.fields.weaveNameSlug.split('/').filter((path) => Boolean(path)))
  } else if (environment.siteDomain === SiteDomain.SYNSISAL) {
    const { items } = await getPageProductEntries<{ weaveNameSlug: string }>(ContentType.OBJ_FLOORING_WEAVE, false, {
      select: ['fields.weaveNameSlug'],
      'fields.status': Status.ACTIVE,
      'fields.keywords[in]': 'Synsisal,synsisal',
    })

    return items.map((item) => item.fields.weaveNameSlug.split('/').filter((path) => Boolean(path)))
  }

  return []
}

const getProductCollection = (
  contentType: ContentType.OBJ_FURNITURE_PRODUCT | ContentType.OBJ_FLOORING_WEAVE,
  productNameSlug?: string,
  isPreview?: boolean,
  query?: Record<string, unknown>,
) => {
  return getProductEntries<ContentfulObjFurnitureProduct | ContentfulObjFlooringWeave>(
    {
      content_type: contentType,
      ...(contentType === ContentType.OBJ_FLOORING_WEAVE && {
        'fields.weaveNameSlug': productNameSlug,
      }),
      ...(contentType === ContentType.OBJ_FURNITURE_PRODUCT && {
        'fields.productNameSlug': productNameSlug,
      }),
      'fields.status': Status.ACTIVE,
      include: 10,
      ...query,
    },
    isPreview,
  )
}

export const getProducts = async (productNameSlug?: string, isPreview?: boolean, query?: Record<string, unknown>) => {
  let productCollection
  let objProducts: Array<unknown> = []
  let keepGet = true
  let skip = 0
  const limit = 100

  while (keepGet) {
    try {
      if (environment.siteDomain === SiteDomain.FURNITURE) {
        productCollection = await getProductCollection(ContentType.OBJ_FURNITURE_PRODUCT, productNameSlug, isPreview, {
          ...query,
          skip,
          limit,
        })
      } else if (environment.siteDomain === SiteDomain.FLOORING || environment.siteDomain === SiteDomain.SISALCARPET) {
        productCollection = await getProductCollection(ContentType.OBJ_FLOORING_WEAVE, productNameSlug, isPreview, {
          ...query,
          skip,
          limit,
        })
      } else if (environment.siteDomain === SiteDomain.SYNSISAL) {
        productCollection = await getProductCollection(ContentType.OBJ_FLOORING_WEAVE, productNameSlug, isPreview, {
          ...query,
          skip,
          limit,
          'fields.keywords[in]': 'Synsisal,synsisal',
        })
      }

      if (productCollection) {
        const collectionItems = productCollection?.items?.length ? productCollection.items : []
        objProducts = objProducts.concat(collectionItems?.map(cleanContentfulEntry))
        skip = objProducts?.length

        if (objProducts?.length >= productCollection?.total) {
          keepGet = false
        }
      } else {
        keepGet = false
      }
    } catch (error) {
      keepGet = false
    }
  }

  return objProducts
}

export const getProduct = async (productNameSlug?: string, isPreview?: boolean, query?: Record<string, unknown>) => {
  const productCollection = await getProducts(productNameSlug, isPreview, query)

  return productCollection?.length ? productCollection[0] : null
}

export const getPages = async (): Promise<ContentfulPgPage[]> => {
  let pages: ContentfulPgPage[] = []
  let keepGet = true
  let skip = 0
  const limit = 100
  while (keepGet) {
    try {
      const collection = await getPageEntries<ContentfulPgPage>(ContentType.PG_PAGE, false, {
        'fields.sites': environment.siteDomain || SiteDomain.FURNITURE,
        include: 0,
        skip,
        limit,
      })

      const collectionItems = collection?.items?.length ? collection.items : []
      pages = pages.concat(collectionItems?.map(cleanContentfulEntry))
      skip = pages?.length

      if (pages?.length >= collection?.total) {
        keepGet = false
      }
    } catch (error) {
      keepGet = false
    }
  }

  return pages
}

export const getPageBySlug = async <T = undefined>(
  slug: string,
  isPreview?: boolean,
): Promise<ContentfulPgPage<T> | null> => {
  const collection = await getPageEntries<ContentfulPgPage>(ContentType.PG_PAGE, isPreview, {
    'fields.slug': slug,
    'fields.sites': environment.siteDomain || SiteDomain.FURNITURE,
    include: 10,
  })
  const page = collection?.items?.length ? collection.items[0] : null

  return page ? cleanContentfulEntry(page) : null
}

export const getPagePreviewbyEntryId = async <T = undefined>(id: string): Promise<ContentfulPgPage<T> | null> => {
  const collection = await getPageEntries<ContentfulPgPage>(ContentType.PG_PAGE, true, {
    'sys.id': id,
    'fields.sites': environment.siteDomain || SiteDomain.FURNITURE,
    include: 10,
  })
  const page = collection?.items?.length ? collection.items[0] : null
  return page ? cleanContentfulEntry(page) : null
}

export const getFooterById = async (id: string, isPreview?: boolean): Promise<ContentfulOrFooter | null> => {
  const footer = await getEntryById<ContentfulOrFooter>(id, isPreview, {
    include: 10,
  })

  return footer ? (cleanContentfulEntry(footer) as ContentfulOrFooter) : null
}

export const getHeaderById = async (id: string, isPreview?: boolean): Promise<ContentfulOrHeader | null> => {
  const header = await getEntryById<ContentfulOrHeader>(id, isPreview, {
    include: 10,
  })

  return header ? (cleanContentfulEntry(header) as ContentfulOrHeader) : null
}

export const getHeaderAndFooter = async (page: PgPageProps, isPreview: boolean) => {
  const siteDomain = environment.siteDomain || SiteDomain.FURNITURE

  const footerId = page.footers[siteDomain]?.sys.id as string
  const headerId = page.headers[siteDomain]?.sys.id as string

  const headerContentful = headerId ? await getHeaderById(headerId, isPreview) : null
  const footerContentful = footerId ? await getFooterById(footerId, isPreview) : null

  return { footerContentful, headerContentful }
}

export const getPagesByOrHeader = async (orHeaderId: string, isPreview?: boolean): Promise<ContentfulPgPage[]> => {
  const client = getClient(isPreview)

  // Get all pages that have the specified OR_HEADER attached
  const collection = await client.getEntries<ContentfulPgPage>({
    content_type: ContentType.PG_PAGE,
    'fields.orHeader.sys.id': orHeaderId,
  })

  const pages = collection?.items?.length ? collection.items : null

  return pages ? pages.map(cleanContentfulEntry) : []
}

export const getSynsisalWeaveColorGroupCollection = async (
  ids: string,
  isSynsisalSite: boolean,
  isPreview: boolean,
): Promise<ContentfulSynsisalWeaveColorGroup[] | null> => {
  if (!isSynsisalSite) return null
  // ids must follow this format: 'id1,id2,id3'
  const products = await getProductEntries<ContentfulSynsisalWeaveColorGroup>(
    {
      content_type: ContentType.OBJ_FLOORING_WEAVE,
      'sys.id[in]': ids,
      'fields.status': Status.ACTIVE,
      select: ['fields.hasSamples', 'fields.samplesDrawerDescription', 'fields.colorSamplesTitle', 'fields.colorGroup'],
      include: 10,
    },
    isPreview,
  )
  if (products) return products.items.map(cleanContentfulEntry)
  else throw new Error('No products found')
}

export const getWeaveColorBorderGroupProduct = async (
  slug: string,
  isPreview: boolean,
): Promise<ContentfulWeaveColorBorderGroup | null> => {
  const products = await getProductEntries<ContentfulWeaveColorBorderGroup>(
    {
      content_type: ContentType.OBJ_FLOORING_WEAVE,
      'fields.weaveNameSlug': slug,
      'fields.status': Status.ACTIVE,
      select: [
        'sys.id',
        'fields.hasSamples',
        'fields.samplesDrawerDescription',
        'fields.colorSamplesTitle',
        'fields.colorGroup',
        'fields.borderGroup',
      ],
      include: 10,
    },
    isPreview,
  )
  if (products) return products.items.map(cleanContentfulEntry)[0]
  else throw new Error('No products found')
}

export const getQuantityProductInfo = async (
  productID: string,
  isPreview: boolean,
): Promise<ContentfulProductQuantityInfo | null> => {
  const products = await getProductEntries<ContentfulProductQuantityInfo>(
    {
      content_type: ContentType.OBJ_FLOORING_WEAVE,
      'sys.id': productID,
      'fields.status': Status.ACTIVE,
      select: ['sys.id', 'fields.minQuantity', 'fields.quantityHelperText'],
      include: 10,
    },
    isPreview,
  )
  if (products) return products.items.map(cleanContentfulEntry)[0]
  else throw new Error('No products found')
}
