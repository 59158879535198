import { getNonCmsPageStaticProps, renderNonCmsPage } from '@utils/non-cms-page'
import { TmLogin } from '../templates'
import { NotFoundPgPage, PgPageProps } from './[[...slug]]'
import { GetStaticPropsContext } from 'next'
import { detectSiteDomain } from '@utils/detect-site-domain'

const { synsisalSite } = detectSiteDomain()

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (synsisalSite) {
    return {
      notFound: true,
    }
  }

  const props = await getNonCmsPageStaticProps(context)

  // Check if page not found
  if (props.notFound) {
    return props
  }

  return {
    ...props,
    revalidate: 10, // Added the revalidate option to trigger incremental static regeneration.
  }
}

const Login = (props: PgPageProps | NotFoundPgPage) => {
  return renderNonCmsPage('Sign in', { ...props, slug: 'login' }, <TmLogin />)
}

export default Login
