import { createClient } from 'contentful'
import { environment } from '@config/environment'

export const getClient = (isPreview = false) =>
  createClient({
    space: environment.contentful.spaceId,
    accessToken: isPreview ? environment.contentful.previewToken : environment.contentful.deliveryToken,
    host: isPreview ? environment.contentful.previewHost : environment.contentful.host,
    environment: environment.contentful.environment,
  })

export const getProductsClient = (isPreview = false) =>
  createClient({
    space: environment.contentful.productsSpaceId,
    accessToken: isPreview ? environment.contentful.productsPreviewToken : environment.contentful.productsDeliveryToken,
    host: isPreview ? environment.contentful.previewHost : environment.contentful.host,
    environment: environment.contentful.environment,
  })
