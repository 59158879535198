import { NotFoundPgPage, PgPageProps } from 'pages/[[...slug]]'
import { Layout } from '../components/layouts'
import { Default404Page } from '.'
import { GetStaticPropsContext } from 'next'
import { getHeaderAndFooter, getPageBySlug } from '@services/contentful'
import {
  containerSectionHorizontalPaddingClasses,
  containerSectionVerticalPaddingClasses,
} from '@curran-catalog/curran-atomic-library'

export const getNonCmsPageStaticProps = async ({ preview }: GetStaticPropsContext) => {
  const isPreview = preview ?? false

  const page = await getPageBySlug('/', isPreview)

  if (page) {
    const { footerContentful, headerContentful } = await getHeaderAndFooter(page, isPreview)

    return {
      props: {
        ...page,
        isPreview,
        footer: footerContentful,
        header: headerContentful,
        notFound: false,
      },
    }
  }

  return { notFound: true }
}

export const renderNonCmsPage = (titlePage: string, props: PgPageProps | NotFoundPgPage, template: JSX.Element) => {
  if ((props as NotFoundPgPage).notFound) {
    return <Default404Page />
  }

  const page = props as PgPageProps
  const { isPreview, header, announcement, footer, slug } = page

  return (
    <Layout
      slug={slug}
      isPreview={isPreview}
      customTitle={titlePage}
      header={{ ...header, announcement: announcement || header.announcement }}
      footer={footer}
    >
      <main
        role="main"
        className={`${containerSectionHorizontalPaddingClasses} ${containerSectionVerticalPaddingClasses} tw-container mx-auto`}
      >
        {template}
      </main>
    </Layout>
  )
}
